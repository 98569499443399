import { Ref, ref } from 'vue'

export const headerRef: Ref<HTMLElement | null> = ref(null)

export const headerIsMovingDown = ref(false)

export const setHeaderIsMovingDown = (value: boolean): void => {
    headerIsMovingDown.value = value
}

export const headerDirectionMove = ref('top')